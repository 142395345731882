import API from '../services/api'

export interface IUser {
    userId?: string
    email?: string
    displayName: string
    phone: string
    role: number
    active: boolean
    location?: string
}
export const getUsers = async () => {
    return await API.get('/user')
}

export const createUser = async (data: IUser) => {
    return API.post('/user/create', data)
}

export const updateUser = async (id: string, data: IUser) => {
    delete data.email
    return API.put(`/user/${id}`, data)
}

export const startResetPassword = async (email: string) => {
    return API.post('/user/reset-password', {
        email,
    })
}

export const completePasswordReset = async (
    token: string,
    password: string,
    confirmPassword: string
) => {
    return API.post(`/user/change-password/${token}`, {
        password,
        confirmPassword,
    })
}
