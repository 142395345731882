import Breadcrumbs from '../../components/Breadcrumbs/Breadcrumbs'
import { Button, Divider, Flex, Select, Spinner, Text } from '@chakra-ui/react'
import React, { useEffect } from 'react'
import { ILocation } from '../../repository/locations'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import CustomTable from '../../components/CustomTable/CustomTable'
import dayjs from 'dayjs'

type FetchStatisticsFields = {
    locationId: string
    startDate: string
    endDate: string
    hidden: boolean
}
export default ({
    statistics,
    locations,
    handleStatisticsFetch,
    loading,
}: {
    statistics: any
    locations: ILocation[]
    loading: boolean
    handleStatisticsFetch: Function
}) => {
    const today = dayjs()
    today.set('hour', 10)
    const tomorrow = today.add(1, 'day')
    tomorrow.set('hour', 3)
    const [startDate, setStartDate] = React.useState(today.toISOString())
    const [endDate, setEndDate] = React.useState(tomorrow.toISOString())
    const {
        register,
        handleSubmit,
        watch,
        getValues,
        setValue,
        control,
        formState: { errors },
    } = useForm<FetchStatisticsFields>({
        defaultValues: {
            hidden: true,
        },
    })

    const cols = [
        { label: 'Product name', field: 'productName' },
        { label: 'Quantity', field: 'quantity' },
    ]
    const orderCols = [
        { label: 'Order Id', field: 'orderId' },
        { label: 'Name', field: 'orderName' },
        { label: 'Created at', field: 'createdAt' },
        { label: 'Order Items', field: 'orderItems' },
        { label: 'Order price', field: 'totalPrice' },
        { label: 'Order discount (%)', field: 'discount' },
        { label: 'Order discount (MKD)', field: 'orderDiscount' },
        { label: 'Fiscal receipt', field: 'fiscalReceipt' },
    ]
    const profitCols = [
        { label: 'Total revenue', field: 'totalRevenue' },
        { label: 'Discounted total', field: 'discountedAmount' },
        { label: 'Fiscal Total', field: 'fiscalTotal' },
        { label: 'Total Orders', field: 'totalOrders' },
        { label: 'Discounted orders', field: 'discountedOrders' },
        { label: 'Non Fiscal Orders', field: 'nonFiscalOrders' },
        { label: 'Fiscal Orders', field: 'fiscalOrders' },
    ]
    const onSubmit: SubmitHandler<FetchStatisticsFields> = (data) => {
        handleStatisticsFetch(
            data.locationId,
            data.startDate,
            data.endDate,
            data.hidden
        )
    }

    const handleChange = (dateChange: any, field: 'startDate' | 'endDate') => {
        const date = new Date(dateChange)

        const utcDateChange = Date.UTC(
            date.getUTCFullYear(),
            date.getUTCMonth(),
            date.getUTCDate(),
            date.getUTCHours(),
            date.getUTCMinutes(),
            date.getUTCSeconds()
        )
        setValue(field, new Date(utcDateChange).toISOString(), {
            shouldDirty: true,
        })
        field === 'startDate'
            ? setStartDate(new Date(utcDateChange).toISOString())
            : setEndDate(new Date(utcDateChange).toISOString())
    }
    const locationId = watch('locationId')
    return (
        <>
            <Breadcrumbs />
            <Divider my={30} />
            <Flex my={10} justifyContent={'flex-end'}>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <Flex>
                        <Flex
                            mr={3}
                            flexDirection={'column'}
                            justifyContent={'center'}
                            alignItems={'center'}
                        >
                            <Text
                                onClick={(e) => {
                                    if (e.detail === 4) {
                                        setValue('hidden', true)
                                    }
                                }}
                            >
                                Please select the information
                            </Text>
                        </Flex>
                        <Flex>
                            <Select
                                placeholder="Select location"
                                mr={5}
                                {...register('locationId')}
                            >
                                {(locations || []).map((location, cid) => (
                                    <option
                                        value={location.locationId}
                                        key={location.locationId}
                                    >
                                        {location.name}
                                    </option>
                                ))}
                            </Select>
                            <Controller
                                name="startDate"
                                control={control}
                                defaultValue={startDate}
                                render={() => (
                                    <DatePicker
                                        showTimeSelect
                                        selected={
                                            startDate
                                                ? new Date(startDate)
                                                : null
                                        }
                                        placeholderText="Select start date"
                                        onChange={(e) =>
                                            handleChange(e, 'startDate')
                                        }
                                    />
                                )}
                            />
                            <Controller
                                name="endDate"
                                control={control}
                                defaultValue={endDate}
                                render={() => (
                                    <DatePicker
                                        showTimeSelect
                                        selected={
                                            endDate ? new Date(endDate) : null
                                        }
                                        placeholderText="Select end date"
                                        onChange={(e) =>
                                            handleChange(e, 'endDate')
                                        }
                                    />
                                )}
                            />
                        </Flex>
                        <Button
                            ml={10}
                            isDisabled={
                                !locationId || !startDate || !endDate || loading
                            }
                            type={'submit'}
                            colorScheme="messenger"
                        >
                            View daily statistics
                        </Button>
                    </Flex>
                </form>
            </Flex>
            <Flex my={10} width={'full'}>
                {loading ? (
                    <Spinner size="xl" mx={'auto'} />
                ) : (
                    <Flex direction={'column'}>
                        <Flex
                            mb={10}
                            h={'max-content'}
                            flexDirection={'column'}
                        >
                            <CustomTable
                                columns={profitCols}
                                data={[
                                    {
                                        ...statistics,
                                    },
                                ]}
                            />
                        </Flex>
                        <Flex mb={10}>
                            <CustomTable
                                columns={cols}
                                data={Object.values(statistics.statistics)}
                            />
                        </Flex>
                        <Flex>
                            <CustomTable
                                columns={orderCols}
                                data={statistics.orders || []}
                            />
                        </Flex>
                    </Flex>
                )}
            </Flex>
        </>
    )
}
