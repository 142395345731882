import {
    Box,
    Divider,
    Flex,
    Grid,
    GridItem,
    StackDivider,
    Text,
} from '@chakra-ui/react'
import FoodCard from '../FoodCard/FoodCard'
import React from 'react'
import './PrintingCard.css'
import { ProductType } from '../../../OrderForm/components/MenuPage/MenuPage'

export default ({
    tacos,
    meats,
    sauces,
    sides,
    toppings,
    item,
    orderId,
    name,
    location,
}: {
    tacos: any[]
    meats: any[]
    sauces: any[]
    sides: any[]
    toppings: any[]
    item: any
    orderId: string
    name: string
    location: string
}) => {
    console.log(item)
    const extras = [...item.extras]
    const sortedExtras = (extras || [])?.sort((itemA: any, itemB: any) =>
        itemA?.productType !== itemB?.productType ? 1 : -1
    )
    console.log('SE', sortedExtras, item)
    return (
        <>
            <Grid
                templateRows={'repeat(2, auto, 1fr)'}
                px={'30px'}
                maxW={'380px'}
                minW={'100%'}
            >
                <GridItem rowSpan={1}>
                    <Text>
                        Name: <b>{name}</b>
                    </Text>
                    <Text>
                        Order ID: <b>{orderId}</b>
                    </Text>
                    {/*<Text>*/}
                    {/*    Location: <b>{location}</b>*/}
                    {/*</Text>*/}
                    <Text>
                        Comment: <b>{item.comment}</b>
                    </Text>
                </GridItem>

                {[ProductType.FOOD, ProductType.COMBO].includes(
                    item.item.productType
                ) && (
                    <>
                        <Text mt={10}>
                            <b>{item.item.name}</b>
                        </Text>
                        <Divider variant={'dashed'} my={4} />
                    </>
                )}
                {[ProductType.FOOD, ProductType.COMBO].includes(
                    item.item.productType
                ) && (
                    <GridItem rowSpan={1}>
                        {extras.map((extraItem, index) => (
                            <>
                                <Text>{extraItem?.name}</Text>
                                {extras[index + 1]?.productType !==
                                    extraItem?.productType && (
                                    <Divider variant={'dashed'} my={4} />
                                )}
                            </>
                        ))}
                    </GridItem>
                )}
                {[ProductType.SEASONAL, ProductType.SIDE].includes(
                    item.item.productType
                ) && (
                    <GridItem mt={'10px'}>
                        <Text>{item.item.name}</Text>
                        <Text pl={'10px'}>{item.item.description}</Text>
                    </GridItem>
                )}
            </Grid>
            <div className="pagebreak"></div>
        </>
    )
}
