import {
    Button,
    Drawer,
    DrawerBody,
    DrawerCloseButton,
    DrawerContent,
    DrawerFooter,
    DrawerHeader,
    DrawerOverlay,
    Flex,
    Image,
    Text,
    Input,
    useDisclosure,
    Heading,
    IconButton,
} from '@chakra-ui/react'
import React, { useState } from 'react'
import EatingLocationCard from '../../components/EatingLocationCard/EatingLocationCard'
import MenuItems from './components/MenuItems/MenuItems'
import ItemEditing from './components/ItemEditing/ItemEditing'
import { useFormContext } from 'react-hook-form'
import { mapOrderProducts } from '../../../../services/product'
import { AddIcon, MinusIcon } from '@chakra-ui/icons'
import { useAppSelector } from '../../../../hooks'
import { RootState } from '../../../../store'
import { getLocalAccount } from '../../../../services/account'

export const SUB_MENU = {
    FOOD: 'FOOD',
    DRINK: 'DRINK',
    DESSERT: 'DESSERT',
    COMBO: 'COMBO',
    SEASONAL: 'SEASONAL',
    SIDE: 'SIDE',
}
export default ({
    food,
    drink,
    meat,
    sauce,
    topping,
    sides,
    selectItem,
    selectedItem,
    clearItem,
    dessert,
    combo,
    seasonal,
    changeItemQuantity,
    handleCancelOrder,
    handleConfirmOrder,
}: {
    food: any[]
    drink: any[]
    meat: any[]
    sauce: any[]
    topping: any[]
    sides: any[]
    selectItem: Function
    selectedItem: null | any
    clearItem: Function
    dessert: any[]
    combo: any[]
    seasonal: any[]
    changeItemQuantity: Function
    handleCancelOrder: Function
    handleConfirmOrder: Function
}) => {
    const [selectedSubMenu, setSelectedSubMenu] = useState(SUB_MENU.FOOD)
    const [isOpen, setIsOpen] = useState(false)
    const locations = useAppSelector((state: RootState) => state.locations)
    const userAccount = getLocalAccount()
    const menu = locations.data.find(
        (location) => location.locationId === userAccount.location
    )
    const form = useFormContext()
    const orderItems = form.getValues('order')
    const totalPrice = mapOrderProducts(
        orderItems,
        locations.selectedLocation.menu || []
    ).reduce((acc: number, item: any) => {
        const itemPrice = item?.product?.price ? +item?.product?.price : 0
        const extrasPrice = item.extras.reduce(
            (acc: number, currentExtra: any) => acc + +currentExtra.price,
            0
        )
        return acc + (itemPrice + extrasPrice) * item.quantity
    }, 0)
    return (
        <Flex
            className={'w-full'}
            justifyContent={'center'}
            flexDirection={'column'}
            w={'100%'}
            mx={'auto'}
        >
            <Image
                src={'/images-static/logo.png'}
                height={'150px'}
                mx={'auto'}
                mb={16}
            />
            <Flex gap={10} justifyContent={'center'}>
                <Flex
                    direction={'column'}
                    gap={5}
                    width={'100px'}
                    minW={'100px'}
                >
                    <Flex
                        direction={'column'}
                        justifyContent={'center'}
                        alignItems={'center'}
                        opacity={selectedSubMenu === SUB_MENU.FOOD ? 1 : 0.5}
                    >
                        <Image
                            cursor={'pointer'}
                            src={'/images-static/tacos.png'}
                            height={75}
                            width={'auto'}
                            onClick={() => setSelectedSubMenu(SUB_MENU.FOOD)}
                        />
                        <Text textAlign={'center'}>Такос</Text>
                    </Flex>
                    <Flex
                        direction={'column'}
                        justifyContent={'center'}
                        alignItems={'center'}
                        opacity={selectedSubMenu === SUB_MENU.DRINK ? 1 : 0.5}
                    >
                        <Image
                            cursor={'pointer'}
                            src={'/images-static/drinks.png'}
                            height={75}
                            width={'auto'}
                            onClick={() => setSelectedSubMenu(SUB_MENU.DRINK)}
                        />
                        <Text textAlign={'center'}>Пијалоци</Text>
                    </Flex>
                    {!!dessert?.length && (
                        <Flex
                            direction={'column'}
                            justifyContent={'center'}
                            alignItems={'center'}
                            opacity={
                                selectedSubMenu === SUB_MENU.DESSERT ? 1 : 0.5
                            }
                        >
                            <Image
                                cursor={'pointer'}
                                src={'/images-static/dessert.png'}
                                height={75}
                                width={'auto'}
                                onClick={() =>
                                    setSelectedSubMenu(SUB_MENU.DESSERT)
                                }
                            />
                            <Text textAlign={'center'}>Десерти</Text>
                        </Flex>
                    )}
                    {!!combo?.length && (
                        <Flex
                            direction={'column'}
                            justifyContent={'center'}
                            alignItems={'center'}
                            opacity={
                                selectedSubMenu === SUB_MENU.COMBO ? 1 : 0.5
                            }
                        >
                            <Image
                                cursor={'pointer'}
                                src={'/images-static/combo.png'}
                                height={75}
                                width={'auto'}
                                onClick={() =>
                                    setSelectedSubMenu(SUB_MENU.COMBO)
                                }
                            />
                            <Text textAlign={'center'}>Комбо</Text>
                        </Flex>
                    )}
                    {!!seasonal?.length && (
                        <Flex
                            direction={'column'}
                            justifyContent={'center'}
                            alignItems={'center'}
                            opacity={
                                selectedSubMenu === SUB_MENU.SEASONAL ? 1 : 0.5
                            }
                        >
                            <Image
                                cursor={'pointer'}
                                src={'/images-static/seasonal.png'}
                                height={75}
                                width={'auto'}
                                onClick={() =>
                                    setSelectedSubMenu(SUB_MENU.SEASONAL)
                                }
                            />
                            <Text textAlign={'center'}>Сезонски такос</Text>
                        </Flex>
                    )}
                    {!!sides?.length && (
                        <Flex
                            direction={'column'}
                            justifyContent={'center'}
                            alignItems={'center'}
                            opacity={
                                selectedSubMenu === SUB_MENU.SIDE ? 1 : 0.5
                            }
                        >
                            <Image
                                cursor={'pointer'}
                                src={'/images-static/sides.png'}
                                height={75}
                                width={'auto'}
                                onClick={() =>
                                    setSelectedSubMenu(SUB_MENU.SIDE)
                                }
                            />
                            <Text textAlign={'center'}>Додатоци</Text>
                        </Flex>
                    )}
                </Flex>
                <Flex minW={'600px'} maxW={'600px'} width={'600px'}>
                    {selectedSubMenu === SUB_MENU.FOOD && (
                        <MenuItems handleSelectItem={selectItem} items={food} />
                    )}
                    {selectedSubMenu === SUB_MENU.DRINK && (
                        <MenuItems
                            handleSelectItem={selectItem}
                            items={drink}
                        />
                    )}
                    {selectedSubMenu === SUB_MENU.DESSERT && (
                        <MenuItems
                            handleSelectItem={selectItem}
                            items={dessert}
                        />
                    )}
                    {selectedSubMenu === SUB_MENU.COMBO && (
                        <MenuItems
                            handleSelectItem={selectItem}
                            items={combo}
                        />
                    )}
                    {selectedSubMenu === SUB_MENU.SEASONAL && (
                        <MenuItems
                            handleSelectItem={selectItem}
                            items={seasonal}
                        />
                    )}
                    {selectedSubMenu === SUB_MENU.SIDE && (
                        <MenuItems
                            handleSelectItem={selectItem}
                            items={sides}
                        />
                    )}
                </Flex>
            </Flex>
            <Flex mt={10} justifyContent={'flex-end'}>
                <Button onClick={() => handleCancelOrder()}>
                    Откажи нарачка
                </Button>
                <Button
                    colorScheme="blue"
                    mx={3}
                    onClick={() => setIsOpen(true)}
                    isDisabled={!orderItems.length}
                >
                    Види ја нарачката
                </Button>
            </Flex>
            {!!selectedItem && (
                <ItemEditing
                    name={`order.${orderItems.length}`}
                    item={selectedItem}
                    itemType={selectedItem?.productType || ''}
                    closeModal={clearItem}
                    meat={meat}
                    sauce={sauce}
                    topping={topping}
                    sides={sides}
                    drinks={drink}
                    closeModalAndSave={() => selectItem(null)}
                />
            )}
            <Drawer
                isOpen={isOpen}
                placement="right"
                onClose={() => setIsOpen(false)}
            >
                <DrawerOverlay />
                <DrawerContent>
                    <DrawerCloseButton tabIndex={-1} />
                    <DrawerHeader>Вашата нарачка</DrawerHeader>

                    <DrawerBody>
                        <Flex
                            className={'w-full'}
                            justifyContent={'center'}
                            flexDirection={'column'}
                            max-w={'50%'}
                            mx={'auto'}
                        >
                            <Image
                                src={'/images-static/logo.png'}
                                height={'150px'}
                                mx={'auto'}
                                mb={16}
                            />
                            <Heading as={'h2'} mb={32}>
                                Вашата нарачка:
                            </Heading>
                            <Flex
                                justifyContent={'space-around'}
                                flexWrap={'wrap'}
                                flexDirection={'column'}
                            >
                                {mapOrderProducts(
                                    orderItems,
                                    locations.selectedLocation.menu
                                ).map((orderItem, itemIndex) => (
                                    <Flex
                                        flexDirection={'column'}
                                        borderBottom={'1px'}
                                        mb={4}
                                        pb={2}
                                    >
                                        <Flex
                                            alignItems={'center'}
                                            justifyContent={'space-between'}
                                        >
                                            <Flex alignItems={'center'}>
                                                <Image
                                                    src={
                                                        orderItem?.product
                                                            ?.image
                                                    }
                                                    height={'50px'}
                                                    mr={4}
                                                />{' '}
                                                <Text>
                                                    {orderItem?.product?.name}
                                                </Text>
                                            </Flex>
                                            <Flex alignItems={'center'}>
                                                <IconButton
                                                    boxSize={6}
                                                    isRound={true}
                                                    colorScheme="blue"
                                                    aria-label="Decrease quantity"
                                                    onClick={() => {
                                                        changeItemQuantity(
                                                            itemIndex,
                                                            -1
                                                        )
                                                    }}
                                                    icon={<MinusIcon />}
                                                />
                                                <Text p={3}>
                                                    {orderItem.quantity}
                                                </Text>

                                                <IconButton
                                                    boxSize={6}
                                                    isRound={true}
                                                    colorScheme="blue"
                                                    aria-label="Increase quantity"
                                                    onClick={() => {
                                                        changeItemQuantity(
                                                            itemIndex,
                                                            +1
                                                        )
                                                    }}
                                                    icon={<AddIcon />}
                                                />
                                            </Flex>
                                        </Flex>
                                        <Flex pl={4} flexDirection={'column'}>
                                            {orderItem.extras.map(
                                                (extra: any) => (
                                                    <Flex alignItems={'center'}>
                                                        <Image
                                                            src={extra.image}
                                                            height={'25px'}
                                                            mr={4}
                                                        />{' '}
                                                        <Text>
                                                            {extra.name}
                                                        </Text>
                                                    </Flex>
                                                )
                                            )}
                                        </Flex>
                                    </Flex>
                                ))}
                            </Flex>
                            <Heading as={'h5'} mb={32}>
                                Вкупно за наплата: {totalPrice} денари
                            </Heading>
                        </Flex>
                    </DrawerBody>

                    <DrawerFooter>
                        <Button
                            variant="outline"
                            mr={3}
                            onClick={() => setIsOpen(false)}
                        >
                            Затвори
                        </Button>
                        <Button
                            colorScheme="blue"
                            isDisabled={!orderItems.length}
                            onClick={() => handleConfirmOrder()}
                            tabIndex={1}
                        >
                            Нарачај
                        </Button>
                    </DrawerFooter>
                </DrawerContent>
            </Drawer>
        </Flex>
    )
}
