import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import {
    createLocation,
    getLocations,
    ILocation,
} from '../../repository/locations'
import { GetThunkAPI } from '@reduxjs/toolkit/dist/createAsyncThunk'

interface ILocationsSlice {
    isLoading: boolean
    data: any[]
    error: boolean
    selectedLocation: {
        menu: any[]
        locationId: string
    }
}

export const fetchAllLocations = createAsyncThunk(
    'locations/fetchAllLocations',
    async (arg, thunkAPI) => {
        const response = await getLocations()
        return response.data.locations
    }
)

const initialState: ILocationsSlice = {
    isLoading: false,
    data: [],
    error: false,
    selectedLocation: {
        menu: [],
        locationId: '',
    },
}
const locationsSlice = createSlice({
    name: 'locations',
    initialState,
    reducers: {
        setLocationsAction: (state, action: PayloadAction<any[]>) => {
            state.data = action.payload
        },
    },
    extraReducers: (builder) =>
        builder
            .addCase(fetchAllLocations.pending, (state, action) => {
                state.isLoading = true
                state.error = false
            })
            .addCase(fetchAllLocations.fulfilled, (state, action) => {
                state.isLoading = false
                state.error = false
                state.data = action.payload
                state.selectedLocation = action.payload[0]
            }),
})

export const { setLocationsAction } = locationsSlice.actions

export default locationsSlice.reducer
