export const mapOrderProducts = (order: any[], productList: any[]) => {
    const mappedProducts: any[] = []
    order.forEach((orderItem) => {
        const foundItem = productList.find(
            (product) => product.productId === orderItem.product
        )
        const mappedExtras: any[] = []
        ;(orderItem.extras || []).forEach((extra: string) => {
            const foundExtra = productList.find(
                (product) => product.productId === extra
            )
            !!foundExtra && mappedExtras.push(foundExtra)
        })
        mappedProducts.push({
            product: foundItem,
            extras: mappedExtras,
            quantity: orderItem.quantity,
        })
    })
    return mappedProducts
}
