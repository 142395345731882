import React, { useEffect, useRef, useState } from 'react'
import {
    Box,
    Button,
    Card,
    CardBody,
    CardHeader,
    Collapse,
    css,
    Divider,
    Flex,
    FormControl,
    FormErrorMessage,
    FormLabel,
    Heading,
    IconButton,
    Input,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    Stack,
    StackDivider,
    Text,
    Tooltip,
    useDisclosure,
    useToast,
    VisuallyHidden,
} from '@chakra-ui/react'
import { calculateTotalPrice } from '../../../../services/price'
import { useReactToPrint } from 'react-to-print'
import {
    CheckIcon,
    CloseIcon,
    ExternalLinkIcon,
    UpDownIcon,
} from '@chakra-ui/icons'
import { ORDER_STATUS } from '../../../OrderForm/orderFormSlice'
import { acceptOrRejectOrder, discountOrder } from '../../orderSlice'
import { useAppDispatch } from '../../../../hooks'
import { ProductType } from '../../../OrderForm/components/MenuPage/MenuPage'
import PrintingCard from '../PrintingCard/PrintingCard'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { FiscalPrinter } from '../../../../services/fiscal-printer'
import { FaDollarSign, FaPercent, FaTag, FaTrash } from 'react-icons/all'
import dayjs from 'dayjs'

interface IOrderCard {
    id: string
    shortId: string
    status: string
    user: string
    items: any[]
    name?: string
    menu?: any[]
    location: string
    createdAt: string
    deleteOrder?: Function
    hideOrder?: Function
}
const OrderCard = ({
    shortId,
    id,
    status,
    user,
    items,
    menu = [],
    location,
    createdAt,
    deleteOrder,
    hideOrder,
}: IOrderCard) => {
    const componentRef = useRef<HTMLDivElement>(null)
    const dispatch = useAppDispatch()
    const toast = useToast()
    const navigate = useNavigate()
    const fiscalPrinter = new FiscalPrinter()
    const { isOpen, onOpen, onClose } = useDisclosure()
    const {
        isOpen: isDiscountModalOpen,
        onOpen: onOpenDiscountModal,
        onClose: onCloseDiscountModal,
    } = useDisclosure()
    const [printPassword, setPrintPassword] = useState('')
    const [showPrintPasswordError, setShowPrintPasswordError] = useState(false)
    const [showPrintButton, setShowPrintButton] = useState(
        status !== ORDER_STATUS.COMPLETED
    )
    let [searchParams, setSearchParams] = useSearchParams()

    const [discountPassword, setDiscountPassword] = useState('')
    const [discount, setDiscount] = useState(0)
    const [showDiscountPasswordError, setShowDiscountPasswordError] =
        useState(false)
    const [tacos, setTacos] = useState(
        menu.filter((item) => item.productType === ProductType.FOOD)
    )
    const [meat, setMeat] = useState(
        menu.filter((item) => item.productType === ProductType.MEAT)
    )
    const [sauces, setSauce] = useState(
        menu.filter((item) => item.productType === ProductType.SAUCE)
    )
    const [topping, setTopping] = useState(
        menu.filter((item) => item.productType === ProductType.EXTRAS)
    )
    const [sides, setSide] = useState(
        menu.filter((item) => item.productType === ProductType.SIDE)
    )
    const [show, setShow] = React.useState(false)

    const handleToggle = () => setShow(!show)
    const validateAndPrint = () => {
        const date = new Date().getDate()
        const printPwd = 'takosi'
        const hour = new Date().getHours()
        setShowPrintPasswordError(false)
        if (true) {
            // if (printPassword === date + printPwd + hour) {
            handlePrint()
            toast({
                title: 'Order printed.',
                description: 'Order was sent to printer successfully!',
                status: 'success',
                duration: 9000,
                isClosable: true,
            })
            closePrintModal()
        } else {
            setShowPrintPasswordError(true)
        }
    }

    const validateAndPrintDiscountReceipt = async () => {
        const printPwd = 'popust'
        const hour = new Date().getHours()
        setShowDiscountPasswordError(false)
        if (printPwd + hour === discountPassword) {
            try {
                await fiscalPrinter.printFiscalReceipt(items, discount)
                await dispatch(
                    discountOrder({
                        orderId: id,
                        discount,
                        callback: () => {},
                    })
                ).unwrap()
                toast({
                    title: 'Order discounted.',
                    description: 'Order was sent to printer successfully!',
                    status: 'success',
                    duration: 9000,
                    isClosable: true,
                })
                closeDiscountModal()
            } catch (e) {
                toast({
                    title: 'Order discount failed',
                    description: 'Order was NOT sent to printer!',
                    status: 'error',
                    duration: 9000,
                    isClosable: true,
                })
            }
        } else {
            setShowDiscountPasswordError(true)
        }
    }

    const printTenPercentDiscountReceipt = async () => {
        try {
            await fiscalPrinter.printFiscalReceipt(items, 10)
            await dispatch(
                discountOrder({
                    orderId: id,
                    discount: 10,
                    callback: () => {},
                })
            ).unwrap()
            toast({
                title: 'Order discounted.',
                description: 'Order was sent to printer successfully!',
                status: 'success',
                duration: 9000,
                isClosable: true,
            })
            closeDiscountModal()
        } catch (e) {
            toast({
                title: 'Order discount failed',
                description: 'Order was NOT sent to printer!',
                status: 'error',
                duration: 9000,
                isClosable: true,
            })
        }
    }

    const closeDiscountModal = () => {
        setDiscount(0)
        setDiscountPassword('')
        setShowDiscountPasswordError(false)
        onCloseDiscountModal()
    }

    const closePrintModal = () => {
        setPrintPassword('')
        setShowPrintPasswordError(false)
        onClose()
    }
    const handlePrint = useReactToPrint({
        content: () => componentRef?.current,
        onAfterPrint: () => navigate('/dashboard/orders'),
    })
    useEffect(() => {
        const createdOrderId = searchParams.get('createdOrderId')
        if (createdOrderId && createdOrderId === id) {
            handlePrint()
        }
    }, [searchParams, id])

    const effectRan = useRef(false)
    let hidePrintTimeout: null | any = null

    useEffect(() => {
        if (!effectRan.current) {
            const createdAtTime = dayjs(createdAt)
            const currentTime = dayjs()
            const timeDiff = currentTime.diff(createdAtTime)
            const thirtyMinutesInMs = 30 * 60 * 1000
            if (timeDiff > thirtyMinutesInMs) {
                setShowPrintButton(false)
            } else {
                const timeWhenToRun = createdAtTime.add(30, 'minutes')
                hidePrintTimeout = setTimeout(() => {
                    setShowPrintButton(false)
                }, timeWhenToRun.diff(currentTime))
            }
        }

        return () => {
            effectRan.current = true
            clearTimeout(hidePrintTimeout)
        }
    }, [])
    return (
        <>
            <Card size={'md'}>
                <CardHeader p={3}>
                    <Flex justify={'space-between'}>
                        <Flex direction={'column'}>
                            <Heading size="md">Name: {user}</Heading>
                            {/*<Heading size="sm">Status: {status}</Heading>*/}
                            <Heading
                                size="sm"
                                onClick={(e) => {
                                    if (
                                        e.detail == 4 &&
                                        status !== ORDER_STATUS?.COMPLETED &&
                                        hideOrder
                                    ) {
                                        hideOrder(id)
                                    }
                                }}
                            >
                                Order ID: {(shortId || '').slice(7)}
                            </Heading>
                            <Heading size="sm">Location: {location}</Heading>
                            <Heading size="sm">
                                Date:{' '}
                                {dayjs(createdAt || '').format(
                                    'DD/MM/YYYY - HH:mm'
                                )}
                            </Heading>
                            {status === ORDER_STATUS.CREATED && (
                                <Flex
                                    my={5}
                                    className={'order-controls'}
                                    css={css({
                                        '@media print': { display: 'none' },
                                    })}
                                >
                                    <Flex
                                        w={'50%'}
                                        justifyContent={'space-between'}
                                    >
                                        <Tooltip label={'Approve order'}>
                                            <CheckIcon
                                                cursor={'pointer'}
                                                color={'green'}
                                                onClick={() =>
                                                    dispatch(
                                                        acceptOrRejectOrder({
                                                            orderId: id,
                                                            status: ORDER_STATUS.ACCEPTED,
                                                            callback:
                                                                handlePrint,
                                                        })
                                                    )
                                                }
                                            />
                                        </Tooltip>
                                        <Tooltip label={'Reject order'}>
                                            <CloseIcon
                                                cursor={'pointer'}
                                                color={'red'}
                                                onClick={() =>
                                                    dispatch(
                                                        acceptOrRejectOrder({
                                                            orderId: id,
                                                            status: ORDER_STATUS.REJECTED,
                                                            callback: () => {},
                                                        })
                                                    )
                                                }
                                            />
                                        </Tooltip>
                                    </Flex>
                                </Flex>
                            )}
                            {/*<Heading size="sm">User: {user}</Heading>*/}
                            <Heading size="sm">
                                Price: {calculateTotalPrice(items)}
                            </Heading>
                            {!deleteOrder && (
                                <Flex
                                    justifyContent={'flex-start'}
                                    alignItems={'center'}
                                >
                                    {showPrintButton && (
                                        <IconButton
                                            mr={2}
                                            icon={<ExternalLinkIcon />}
                                            aria-label={'Click to Print'}
                                            onClick={onOpen}
                                            display={
                                                status === ORDER_STATUS.CREATED
                                                    ? 'none'
                                                    : 'block'
                                            }
                                        />
                                    )}
                                    <IconButton
                                        mr={2}
                                        aria-label={
                                            'Click to print fiscal receipt'
                                        }
                                        backgroundColor={'green'}
                                        display={
                                            status === ORDER_STATUS.COMPLETED
                                                ? 'none'
                                                : 'flex'
                                        }
                                        onClick={async () => {
                                            try {
                                                const result = await dispatch(
                                                    acceptOrRejectOrder({
                                                        orderId: id,
                                                        status: ORDER_STATUS.COMPLETED,
                                                        callback: () => {},
                                                    })
                                                ).unwrap()
                                                await fiscalPrinter.printFiscalReceipt(
                                                    items
                                                )
                                            } catch (e) {
                                                alert('Something went wrong')
                                            }
                                        }}
                                        icon={<FaDollarSign color={'white'} />}
                                    />
                                    <Button
                                        mr={2}
                                        size={'md'}
                                        p={2}
                                        fontSize={12}
                                        aria-label={
                                            'Click to print fiscal receipt with 10% discount'
                                        }
                                        display={
                                            status === ORDER_STATUS.COMPLETED
                                                ? 'none'
                                                : 'flex'
                                        }
                                        onClick={async () => {
                                            try {
                                                await printTenPercentDiscountReceipt()
                                            } catch (e) {
                                                alert('Something went wrong')
                                            }
                                        }}
                                    >
                                        10%
                                    </Button>
                                    <IconButton
                                        aria-label={
                                            'Click to print fiscal receipt with discount'
                                        }
                                        display={
                                            status === ORDER_STATUS.COMPLETED
                                                ? 'none'
                                                : 'flex'
                                        }
                                        onClick={onOpenDiscountModal}
                                        icon={<FaPercent />}
                                    />
                                </Flex>
                            )}
                            {deleteOrder && (
                                <IconButton
                                    aria-label={'delete order'}
                                    icon={<FaTrash />}
                                    onClick={() => deleteOrder()}
                                />
                            )}
                        </Flex>
                    </Flex>
                </CardHeader>
                <CardBody p={3} pt={0}>
                    <Collapse startingHeight={60} in={show}>
                        <Stack divider={<StackDivider />} spacing="4">
                            <Box>
                                <Heading
                                    size="xs"
                                    textTransform="uppercase"
                                    mb={4}
                                >
                                    Summary
                                </Heading>
                                {items?.map((item) => (
                                    <Flex direction={'column'}>
                                        <Flex justifyContent={'space-between'}>
                                            <Flex direction={'column'}>
                                                <Flex>{item.item.name}</Flex>
                                                <Flex
                                                    ml={4}
                                                    flexDirection={'column'}
                                                >
                                                    {item?.extras?.map(
                                                        (extra: any) => (
                                                            <Text>
                                                                {extra.name}
                                                            </Text>
                                                        )
                                                    )}
                                                </Flex>
                                                {item.comment && (
                                                    <Flex>
                                                        <Text mr={2}>
                                                            <b>Comment:</b>
                                                        </Text>{' '}
                                                        {item.comment}
                                                    </Flex>
                                                )}
                                            </Flex>
                                            <Flex>{item.quantity}</Flex>
                                        </Flex>
                                        <Divider my={4} />
                                    </Flex>
                                ))}
                            </Box>
                        </Stack>
                    </Collapse>
                    {items.length > 1 && (
                        <Flex justifyContent={'flex-end'}>
                            <IconButton
                                ml={'auto'}
                                isRound={true}
                                backgroundColor={'transparent'}
                                icon={<UpDownIcon />}
                                size={'sm'}
                                onClick={handleToggle}
                                aria-label={'see-order'}
                            />
                        </Flex>
                    )}
                </CardBody>
            </Card>

            <VisuallyHidden>
                <Flex ref={componentRef} flexDirection={'column'}>
                    {items.length > 1 &&
                        items
                            .filter((item) =>
                                [
                                    ProductType.FOOD,
                                    ProductType.SEASONAL,
                                    ProductType.COMBO,
                                    ProductType.SIDE,
                                ].includes(item.item.productType)
                            )
                            .map((taco: any) =>
                                Array.from(Array(taco.quantity).keys()).map(
                                    (ind) => (
                                        <PrintingCard
                                            orderId={shortId}
                                            name={user}
                                            tacos={tacos}
                                            meats={meat}
                                            sauces={sauces}
                                            sides={sides}
                                            toppings={topping}
                                            item={taco}
                                            location={location}
                                        />
                                    )
                                )
                            )}
                    <Flex direction={'column'} px={'30px'}>
                        <Heading size="md">Name: {user}</Heading>
                        <Heading size="sm">Order ID: {shortId}</Heading>
                        <Heading size="sm">Location: {location}</Heading>
                        <Heading size="sm">
                            Date:{' '}
                            {dayjs(createdAt || '').format(
                                'DD/MM/YYYY - HH:mm'
                            )}
                        </Heading>
                        {/*<Heading size="sm">User: {user}</Heading>*/}
                        <Heading size="sm">
                            Price: {calculateTotalPrice(items)}
                        </Heading>
                    </Flex>
                    <Stack divider={<StackDivider />} spacing="4" px={'30px'}>
                        <Box my={'30px'}>
                            {items?.map((item) => {
                                console.log(item)
                                const extras = item?.extras?.length
                                    ? ([...item?.extras] || []).sort(
                                          (itemA: any, itemB: any) =>
                                              itemA?.productType !==
                                              itemB?.productType
                                                  ? 1
                                                  : -1
                                      )
                                    : []
                                return (
                                    <Flex direction={'column'}>
                                        <Flex justifyContent={'space-between'}>
                                            <Flex direction={'column'}>
                                                <Flex>{item.item.name}</Flex>
                                                <Flex
                                                    ml={4}
                                                    flexDirection={'column'}
                                                >
                                                    {extras?.map(
                                                        (
                                                            extra: any,
                                                            index: number
                                                        ) => {
                                                            return (
                                                                <>
                                                                    <Text>
                                                                        {
                                                                            extra.name
                                                                        }
                                                                    </Text>
                                                                    {extras[
                                                                        index +
                                                                            1
                                                                    ]
                                                                        ?.productType !==
                                                                        extra?.productType && (
                                                                        <Divider
                                                                            variant={
                                                                                'dashed'
                                                                            }
                                                                            my={
                                                                                4
                                                                            }
                                                                        />
                                                                    )}
                                                                </>
                                                            )
                                                        }
                                                    )}
                                                </Flex>
                                                {item.comment && (
                                                    <Flex>
                                                        <Text mr={2}>
                                                            <b>Comment:</b>
                                                        </Text>{' '}
                                                        {item.comment}
                                                    </Flex>
                                                )}
                                            </Flex>
                                            <Flex>{item.quantity}</Flex>
                                        </Flex>
                                        <Divider my={4} />
                                    </Flex>
                                )
                            })}
                        </Box>
                    </Stack>
                </Flex>
            </VisuallyHidden>
            <Modal
                blockScrollOnMount={false}
                isOpen={isOpen}
                onClose={closePrintModal}
            >
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Print order</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <Text fontWeight="bold" mb="1rem">
                            Insert password to confirm printing of order
                        </Text>
                        <FormControl isInvalid={showPrintPasswordError}>
                            <FormLabel>Password</FormLabel>
                            <Input
                                type={'password'}
                                onChange={(e) =>
                                    setPrintPassword(e.target.value)
                                }
                            />
                            {showPrintPasswordError && (
                                <FormErrorMessage>
                                    Password is invalid!
                                </FormErrorMessage>
                            )}
                        </FormControl>
                    </ModalBody>

                    <ModalFooter>
                        <Button mr={3} onClick={closePrintModal}>
                            Close
                        </Button>
                        <Button colorScheme="blue" onClick={validateAndPrint}>
                            Print
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>

            <Modal
                blockScrollOnMount={false}
                isOpen={isDiscountModalOpen}
                onClose={closeDiscountModal}
            >
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Discount price</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <Text fontWeight="bold" mb="1rem">
                            Insert password and discount amount in percentages
                        </Text>
                        <FormControl>
                            <FormLabel>Discount amount (%)</FormLabel>
                            <Input
                                type={'number'}
                                onChange={(e) =>
                                    setDiscount(parseFloat(e.target.value))
                                }
                            />
                        </FormControl>
                        <FormControl isInvalid={showDiscountPasswordError}>
                            <FormLabel>Password</FormLabel>
                            <Input
                                type={'password'}
                                onChange={(e) =>
                                    setDiscountPassword(e.target.value)
                                }
                            />
                            {showDiscountPasswordError && (
                                <FormErrorMessage>
                                    Password is invalid!
                                </FormErrorMessage>
                            )}
                        </FormControl>
                    </ModalBody>

                    <ModalFooter>
                        <Button mr={3} onClick={closeDiscountModal}>
                            Close
                        </Button>
                        <Button
                            colorScheme="blue"
                            onClick={validateAndPrintDiscountReceipt}
                        >
                            Print
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    )
}
export default OrderCard
