import { configureStore } from '@reduxjs/toolkit'
import locationsSlice from './containers/Locations/locationsSlice'
import locationFormSlice from './containers/LocationForm/locationFormSlice'
import usersSlice from './containers/Users/usersSlice'
import userFormSlice from './containers/UserForm/userFormSlice'
import productsSlice from './containers/Products/productsSlice'
import productFormSlice from './containers/ProductForm/productFormSlice'
import orderSlice from './containers/Orders/orderSlice'
import orderFormSlice from './containers/OrderForm/orderFormSlice'
import loginSlice from './containers/Login/loginSlice'
import forgotPasswordSlice from './containers/ForgotPassword/forgotPasswordSlice'
import resetPasswordSlice from './containers/ResetPassword/resetPasswordSlice'
import statisticsSlice from './containers/Statistics/StatisticsSlice'
import adminOrdersSlice from './containers/AdminOrders/adminOrdersSlice'

// ...

export const store = configureStore({
    reducer: {
        login: loginSlice,
        forgotPassword: forgotPasswordSlice,
        resetPassword: resetPasswordSlice,
        locations: locationsSlice,
        locationForm: locationFormSlice,
        users: usersSlice,
        userForm: userFormSlice,
        products: productsSlice,
        productForm: productFormSlice,
        orders: orderSlice,
        orderForm: orderFormSlice,
        statistics: statisticsSlice,
        adminOrderSlice: adminOrdersSlice,
    },
})

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch
