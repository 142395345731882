import { UnicodeToWin1251, utf8ToCp1251Map } from './fiscal-printer-mapping'
// import { encode, decode, labels } from 'windows-1251'

let port: any = null

export class FiscalPrinter {
    private preamble = 1
    private terminator = 3
    private postamble = 5
    private seq = 32
    private fiscOperator = '1,0000,1'
    private lenShift = 32
    constructor() {}

    checkForPorts = async () => {
        //@ts-ignore
        const ports = await navigator.serial.getPorts()

        if (ports.length) {
            port = ports[0]
            try {
                await port.close()
            } catch (e) {
                console.log('Port is closed! Opening a new connection')
            }
            await port.open({
                baudRate: 9600,
                parity: 'none',
                stopBits: 1,
                dataBits: 8,
            })
            return true
        }
        return false
    }

    connectPort = async () => {
        //@ts-ignore
        port = await navigator.serial.requestPort()
        try {
            await port.close()
        } catch (e) {
            console.log('Port is closed! Opening a new connection')
        }
        await port.open({
            baudRate: 9600,
            parity: 'none',
            stopBits: 1,
            dataBits: 8,
        })
    }

    getSeq() {
        return +(Date.now() % 2 == 0)
    }

    sendMessage = async (command: number, inputData: string = '') => {
        try {
            const formattedInputData = UnicodeToWin1251(inputData)
            console.log('FID', formattedInputData, port)
            const textEncoder = new TextEncoder()
            const dataText = textEncoder.encode(formattedInputData.text)
            const dataLength = formattedInputData.arr.length + this.lenShift + 4
            const seq = this.seq++
            const sum =
                dataLength +
                seq +
                command +
                this.postamble +
                formattedInputData.arr.reduce((acc, entry) => acc + entry, 0)
            const dataMessage = [
                this.preamble,
                dataLength,
                seq,
                command,
                ...formattedInputData.arr,
                this.postamble,
                sum / 256 / 16 + 48,
                ((sum / 256) % 16) + 48,
                (sum % 256) / 16 + 48,
                ((sum % 256) % 16) + 48,
                this.terminator,
            ]
            const writer = port.writable.getWriter()

            const data = new Uint8Array(dataMessage) // hello
            const rW = await writer.write(data)
            console.log(data, rW)
            writer.releaseLock()
            await new Promise((r) => setTimeout(r, 60))
            return true
        } catch (e) {
            console.log('Error while writing')
            return false
        }
    }

    async readMessage() {
        const reader = port.readable.getReader()
        try {
            const readerResponse = await reader.read()
            const { value } = readerResponse
            const dataSection = value.slice(4)
            const responseDelimiterIndex = dataSection.indexOf(4)
            let readableOutput = ''
            if (dataSection.length > 1) {
                const readableOutputBuffer = value.slice(
                    4,
                    responseDelimiterIndex
                )
                const textDecoder = new TextDecoder()
                readableOutput = textDecoder.decode(readableOutputBuffer)
                console.log(readableOutput)
            }
            reader.releaseLock()
            await new Promise((r) => setTimeout(r, 60))
            return {
                readableOutput,
                value,
            }
        } catch (e) {
            console.error(e)
        }
        reader.releaseLock()
    }

    async printFiscalReceipt(items: any, discount: number = 0) {
        // if(!port) {alert('No fiscal printer selected!'); return;}
        const fiscalItems: any[] = []

        items.forEach((item: any) => {
            fiscalItems.push({
                name: item.item.name,
                quantity: item.quantity,
                price: item.item.price,
            })
            ;(item.extras || []).forEach((extra: any) => {
                if (extra.price > 0) {
                    fiscalItems.push({
                        name: '+ ' + extra.name,
                        quantity: item.quantity,
                        price: extra.price,
                    })
                }
            })
        })

        await this.sendMessage(48, '1,0000,1')
        await new Promise((r) => setTimeout(r, 60))
        // console.log(await this.readMessage())
        console.log(fiscalItems)
        for (const item of fiscalItems) {
            let message = `${item.name}\t@${
                item.taxRate || 'Г'
            }${item.price.toString()}*${item.quantity
                .toPrecision(3)
                .toString()}`
            if (discount > 0 && discount < 100) {
                message += `,-${discount.toFixed(2)}`
            }
            console.log('FP Message ' + message)

            await this.sendMessage(49, message)
            await new Promise((r) => setTimeout(r, 60))
            await this.readMessage()
            await new Promise((r) => setTimeout(r, 60))

            // await this.sendMessage(52, message)
            // await new Promise(r => setTimeout(r, 60));
            // await this.readMessage();
            // await new Promise(r => setTimeout(r, 60));
        }

        await this.sendMessage(53)
        await new Promise((r) => setTimeout(r, 60))
        console.log(await this.readMessage())
        await new Promise((r) => setTimeout(r, 60))
        await this.sendMessage(56)
        await new Promise((r) => setTimeout(r, 60))
        console.log(await this.readMessage())
        await new Promise((r) => setTimeout(r, 60))
    }

    async printControlReport(inputData: string) {
        if (!port) {
            alert('No fiscal printer selected!')
            return
        }
        await this.sendMessage(69, inputData)
        await new Promise((r) => setTimeout(r, 60))
        console.log(await this.readMessage())
        await new Promise((r) => setTimeout(r, 60))
    }
}
