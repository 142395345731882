import React, { useState } from 'react'
import { Box, Flex, useDisclosure } from '@chakra-ui/react'
import Sidebar from '../components/Sidebar/Sidebar'
import { AdminRoutes } from '../routes/AdminRoutes'
import {
    Navigate,
    Outlet,
    redirect,
    Route,
    useNavigate,
} from 'react-router-dom'
import { isAuth } from '../services/auth'

export default () => {
    const { isOpen, onToggle } = useDisclosure()
    if (!isAuth()) {
        return <Navigate to={'/login'} replace />
    }
    return (
        <Flex style={{ background: '#f4f7fe' }}>
            <Sidebar isOpen={isOpen} onToggle={onToggle} />
            <Box w="100%" p={10}>
                <Outlet />
            </Box>
        </Flex>
    )
}
