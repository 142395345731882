import {
    Box,
    Button,
    Flex,
    FormControl,
    FormErrorMessage,
    FormLabel,
    IconButton,
    Image,
    Input,
    InputGroup,
    InputRightElement,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    Text,
    useDisclosure,
    useToast,
    VisuallyHidden,
} from '@chakra-ui/react'
import React, { useEffect, useRef, useState } from 'react'
import { useAppDispatch, useAppSelector } from '../../hooks'
import { RootState } from '../../store'
import {
    cancelAction,
    createNewOrder,
    IOrderItem,
    ORDER_STATUS,
    setOrder,
} from './orderFormSlice'
import {
    Controller,
    FormProvider,
    SubmitHandler,
    useForm,
} from 'react-hook-form'
import { IProduct } from '../../repository/products'
import {
    AutoComplete,
    AutoCompleteInput,
    AutoCompleteItem,
    AutoCompleteList,
} from '@choc-ui/chakra-autocomplete'
import { AddIcon } from '@chakra-ui/icons'
import { fetchAllProducts } from '../Products/productsSlice'
import { calculateTotalPrice } from '../../services/price'
import SelectLocation from './components/SelectLocation/SelectLocation'
import MenuPage, { ProductType } from './components/MenuPage/MenuPage'
import DetailsPage from './components/DetailsPage/DetailsPage'
import { fetchAllLocations } from '../Locations/locationsSlice'
import locations from '../Locations/Locations'
import { useNavigate, useSearchParams } from 'react-router-dom'
import OrderCard from '../Orders/components/OrderCard/OrderCard'

export default () => {
    const { isOpen, onOpen, onClose } = useDisclosure()
    const dispatch = useAppDispatch()
    const toast = useToast()
    const navigate = useNavigate()
    let [searchParams, setSearchParams] = useSearchParams()
    const orderForm = useAppSelector((state: RootState) => state.orderForm)
    const [orderStep, setOrderStep] = useState(0)
    const locations = useAppSelector((state: RootState) => state.locations)
    const [createdOrder, setCreatedOrder] = useState(null as null | any)
    const [shouldPrint, setShouldPrint] = useState(false)
    useEffect(() => {
        dispatch(fetchAllProducts())
        dispatch(fetchAllLocations())
    }, [])

    const methods = useForm({
        defaultValues: {
            orderPlace: '',
            order: [] as any[],
            name: '',
            number: '',
        },
    })
    const fileUploadRef = useRef<HTMLInputElement>(null)
    const onSubmit: SubmitHandler<any> = async (data) => {
        try {
            const mappedProducts = data.order.map((orderItem: any) => {
                const foundItem = locations.selectedLocation.menu.find(
                    (item) => orderItem.product === item.productId
                )

                return {
                    item: foundItem,
                    quantity: orderItem.quantity,
                    comment: orderItem.comment,
                    extras: orderItem.extras.map((extra: any) =>
                        locations.selectedLocation.menu.find(
                            (item) => extra === item.productId
                        )
                    ),
                }
            })

            const createdOrderResponse = await dispatch(
                createNewOrder({
                    ...data,
                    locationId: locations.selectedLocation.locationId,
                    orderPlace: data.orderPlace,
                    items: mappedProducts,
                })
            ).unwrap()
            setCreatedOrder(createdOrderResponse)
            setSearchParams({ createdOrderId: createdOrderResponse.orderId })
            toast({
                title: 'Креирање нарачка.',
                description:
                    'Нарачката беше креирана успешно. Ќе биде автоматски одобрена на следниот екран',
                status: 'success',
                duration: 9000,
                isClosable: true,
            })
            // setTimeout(() => {
            //     navigate(`/dashboard/orders`)
            // }, 500)
        } catch (e) {
            toast({
                title: 'Креирање нарачка.',
                description:
                    'Нарачката не беше креирана. Ве молиме обидете се повторно.',
                status: 'error',
                duration: 9000,
                isClosable: true,
            })
        }
    }

    const closeModal = () => {
        dispatch(cancelAction())
        onClose()
    }

    useEffect(() => {
        if (createdOrder) {
            console.log('HERE')
            const drinksAndDesserts = createdOrder?.items?.filter(
                (createdItem: any) =>
                    [ProductType.DRINK, ProductType.DESSERT].includes(
                        createdItem.item.productType
                    )
            )
            console.log(
                'HERE',
                createdOrder,
                drinksAndDesserts.length,
                drinksAndDesserts.length === createdOrder.items.length
            )
            if (
                drinksAndDesserts.length &&
                drinksAndDesserts.length === createdOrder.items.length
            ) {
                navigate('/dashboard/orders')
            } else {
                setShouldPrint(true)
            }
        }
    }, [createdOrder])

    const formValues = methods.watch()

    return (
        <>
            {orderForm.isVisible && (
                <FormProvider {...methods}>
                    <form onSubmit={methods.handleSubmit(onSubmit)}>
                        {orderStep === 0 && (
                            <SelectLocation
                                handleClick={() => setOrderStep(orderStep + 1)}
                            />
                        )}
                        {orderStep === 1 && (
                            <MenuPage setOrderStep={setOrderStep} />
                        )}
                        {orderStep === 2 && <DetailsPage />}
                    </form>
                </FormProvider>
            )}
            {shouldPrint && (
                <VisuallyHidden>
                    <OrderCard
                        shortId={createdOrder.shortOrderId}
                        id={createdOrder.orderId}
                        status={createdOrder.orderStatus}
                        user={createdOrder.orderName || createdOrder.userId}
                        items={createdOrder.items}
                        menu={locations.selectedLocation.menu}
                        location={createdOrder.orderPlace}
                        createdAt={createdOrder.createdAt}
                    />
                </VisuallyHidden>
            )}
        </>
    )
}
