import {
    Box,
    Button,
    FormControl,
    FormErrorMessage,
    FormLabel,
    Input,
    InputGroup,
    InputRightElement,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    useDisclosure,
} from '@chakra-ui/react'
import React, { useRef, useState } from 'react'
import { useAppDispatch, useAppSelector } from '../../hooks'
import { RootState } from '../../store'
import { cancelAction, createLocationAction } from './locationFormSlice'
import { ImEye, ImEyeBlocked } from 'react-icons/im'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import { createLocation, ILocation } from '../../repository/locations'
import { loginUser } from '../Login/loginSlice'
import { fetchAllLocations } from '../Locations/locationsSlice'
import { MultiSelect, Option } from 'chakra-multiselect'

type LocationInputs = {
    locationId?: string
    address: string
    name: string
    phone: string
    menu: Option | Option[]
}
export default () => {
    const { isOpen, onOpen, onClose } = useDisclosure()
    const finalRef = useRef(null)
    const dispatch = useAppDispatch()
    const locationForm = useAppSelector(
        (state: RootState) => state.locationForm
    )
    const products = useAppSelector((state: RootState) => state.products)
    const [selectValue, setSelectValue] = useState(
        (locationForm?.data?.menu || []).map(
            (item: any) =>
                ({
                    label: item.name as string,
                    value: item.productId as string,
                }) as Option
        ) as Option | Option[]
    )

    const {
        register,
        handleSubmit,
        watch,
        control,
        setValue,
        formState: { errors },
    } = useForm<LocationInputs>({ defaultValues: { ...locationForm.data } })

    const onSubmit: SubmitHandler<LocationInputs> = async (data) => {
        const menuOptions = data.menu
        const menu: any[] = [];
        ((menuOptions || []) as Option[]).forEach((menuItem: Option) => {
            const foundItem = products.data.find(
                (product: any) => product.productId === menuItem.value
            )
            menu.push(foundItem)
        })
        await dispatch(createLocationAction({ ...data, menu: menu }))
        dispatch(fetchAllLocations())
        closeModal()
    }

    const closeModal = () => {
        dispatch(cancelAction())
        onClose()
    }

    return (
        <>
            {locationForm.isVisible && (
                <Modal
                    finalFocusRef={finalRef}
                    isOpen={locationForm.isVisible}
                    onClose={closeModal}
                >
                    <ModalOverlay />
                    <ModalContent>
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <ModalHeader>Create a location</ModalHeader>
                            <ModalCloseButton />
                            <InputGroup size="md">
                                <Input
                                    type={'hidden'}
                                    {...register('locationId')}
                                />
                            </InputGroup>
                            <ModalBody>
                                <FormControl
                                    isInvalid={!!errors.name}
                                    mb={'30px'}
                                >
                                    <FormLabel>
                                        Name<span>*</span>
                                    </FormLabel>
                                    <InputGroup size="md">
                                        <Input
                                            pr="4.5rem"
                                            type={'text'}
                                            placeholder="Enter location name"
                                            {...register('name', {
                                                required: true,
                                            })}
                                        />
                                    </InputGroup>
                                    {!!errors.name && (
                                        <FormErrorMessage>
                                            Location name is required.
                                        </FormErrorMessage>
                                    )}
                                </FormControl>
                                <FormControl
                                    isInvalid={!!errors.address}
                                    mb={'30px'}
                                >
                                    <FormLabel>
                                        Address<span>*</span>
                                    </FormLabel>
                                    <InputGroup size="md">
                                        <Input
                                            pr="4.5rem"
                                            type={'text'}
                                            placeholder="Enter address"
                                            {...register('address', {
                                                required: true,
                                            })}
                                        />
                                    </InputGroup>
                                    {!!errors.address && (
                                        <FormErrorMessage>
                                            Address is required.
                                        </FormErrorMessage>
                                    )}
                                </FormControl>
                                <FormControl
                                    isInvalid={!!errors.phone}
                                    mb={'30px'}
                                >
                                    <FormLabel>
                                        Contact<span>*</span>
                                    </FormLabel>
                                    <InputGroup size="md">
                                        <Input
                                            pr="4.5rem"
                                            type={'text'}
                                            placeholder="Enter contact"
                                            {...register('phone', {
                                                required: true,
                                            })}
                                        />
                                    </InputGroup>
                                    {!!errors.phone && (
                                        <FormErrorMessage>
                                            Phone is required.
                                        </FormErrorMessage>
                                    )}
                                </FormControl>
                                <Controller
                                    control={control}
                                    render={({
                                        field: { onChange, onBlur, ref },
                                    }) => (
                                        <MultiSelect
                                            options={products.data.map(
                                                (product) => ({
                                                    label: product.name,
                                                    value: product.productId,
                                                })
                                            )}
                                            value={selectValue}
                                            label="Choose the menu items"
                                            onChange={(val) => {
                                                setSelectValue(val)
                                                setValue('menu', val)
                                            }}
                                        />
                                    )}
                                    name={'menu'}
                                />
                            </ModalBody>

                            <ModalFooter>
                                <Button mr={3} onClick={closeModal}>
                                    Cancel
                                </Button>
                                <Button colorScheme="messenger" type="submit">
                                    Save
                                </Button>
                            </ModalFooter>
                        </form>
                    </ModalContent>
                </Modal>
            )}
        </>
    )
}
