import API from '../services/api'

export interface ILocation {
    locationId?: string
    name: string
    address: string
    phone: string
    menu?: any[]
}
export const getLocations = async () => {
    return await API.get('/location')
}

export const createLocation = async (data: ILocation) => {
    return await API.post('/location/create', data)
}

export const updateLocation = async (id: string, data: ILocation) => {
    return await API.put(`/location/${id}`, data)
}
